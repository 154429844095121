import React from "react";

import {
  Container,
  Col,
  Row,
  Form
} from "react-bootstrap";
import { convertToHoursMinutesSeconds } from "utils/utility";

export default function  RepeatableRoomFeatureForm(props) {
    const {values, handleChange, handleBlur, errors} = props
    return (
        <Container fluid>
            <Row>
                <Col md={6} className="mt-4">
                    <div className="form-floating  go-bottom">
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="name"
                            value={values?.name}
                            placeholder="Feature Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors && errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                        <label htmlFor="name">Feature Name</label>
                    </div>
                
                </Col>
                <Col md={6} className="mt-4">
                <div className="form-floating mb-4 go-bottom">
                    <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={values?.quantity}
                        placeholder="Quantity"
                        onChange={handleChange}
                        min={0}
                        required
                        onBlur={handleBlur}
                    />
                    {errors && errors.quantity && <Form.Text className="text-danger">{errors.quantity}</Form.Text>}
                    <label htmlFor="quantity">Quantity</label>
                </div>
                </Col>
                <Col md={6} className="mt-4">
                <div className="form-floating mb-4 go-bottom">
                    <input
                        type="number"
                        className="form-control"
                        name="timeInMinutes"
                        value={values?.timeInMinutes}
                        placeholder="timeInMinutes"
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                    />
                    {errors && errors.timeInMinutes && <Form.Text className="text-danger">{errors.timeInMinutes}</Form.Text>}
                    <label htmlFor="quantity">Time In Minutes</label>
                </div>
                </Col>
                <Col md={6} className="mt-4">
                <div className="form-floating mb-4 go-bottom">
                    <input
                    required
                    type="text"
                    className="form-control"
                    name="workloadInMinutes"
                    value={convertToHoursMinutesSeconds((values?.quantity||0)*(values?.timeInMinutes * 60))}
                    placeholder="Workload"
                    disabled
                    />
                    <label htmlFor="workloadInMinutes">Workload</label>
                </div>
                </Col>
            </Row>
        </Container>
    )
}