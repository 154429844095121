import React, { useCallback, useEffect, useState } from "react";
import BaseTable from "components/BaseTable.js";
import { fetchTrainingVideoEmailHistory } from "services/cleaner";
import { FileText } from 'react-bootstrap-icons';
import {handleDownloadFile} from 'utils/utility';
export default function CleanerEmailHistory(props) {
    const {cleaner} = props;
    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        if (cleaner && cleaner.id) {
            let queryParams = params || {};
            queryParams = {...queryParams, cleaners: cleaner.id}
            const response = await fetchTrainingVideoEmailHistory(page, pageSize, queryParams);
            setTotalCount(response.count)
            setRows(response.results)
        }
        setLoading(false);
    }, [page, pageSize, params]);

    useEffect(() => {
        fetchData();
    }, [page, pageSize, params])
    const handleFetch = (currentPage, currentPageSize, currentParams) => {
        setPage(currentPage);
        setPageSize(currentPageSize);
        setParams(currentParams);
    }
    const cols = [
        {
            title: 'Date Sent',
            dataIndex: 'sentAt',
            width: '20%',
        },
        { 
            title: 'Message',
            dataIndex: 'message',
            width: '40%',
        }, 
        { 
            title: 'Classes',
            dataIndex: 'cleanClasses',
            render: (row) => {
                if (row && row.length > 0)
                    return <ul>
                        {row.map(clean_class => (<li>{clean_class.title}</li>))}
                    </ul>
                else
                    return "-"
            }
        },
        { 
            title: 'Attachments',
            dataIndex: 'trainingVideos',
            render: (row) => {
                if (row && row.length > 0)
                    return <div className="d-flex justify-content-between flex-wrap">
                        {
                            row.map(video => (
                                <a className="text-center" width={25} onClick={() => handleDownloadFile(video.file, video.fileName)}> 
                                    <FileText size={50} />
                                    <div className="file-title text-center" >
                                        {video.fileName || ""}
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                else
                    return "-"
            }
        },
    ]
    return (
        <BaseTable 
            cols={cols}
            rows={rows}
            totalCount={totalCount}
            getData={handleFetch}
        />
    )
}