import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Files from 'react-files';
import exportIcon from "components/Dashboard/assets/export-icon.svg";   
import { FileText } from 'react-bootstrap-icons';
import { toast } from "react-toastify";

export default function CleanerEmailForm(props) {
    const {values, handleChange, handleBlur, errors, classList, handleClassesChange, handleFilesUploading} = props;
    return (<Row>
        <Col md={12}>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    as="textarea"
                    name="emails"
                    rows="5"
                    value={values.emails.map(email => ` ${email}`)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                />
            </Form.Group>
        </Col>
        <Col md={12}>
            <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                    as="textarea"
                    name="message"
                    rows="5"
                    value={values.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors && errors.message && <Form.Text className="text-danger">{errors.message}</Form.Text>}
            </Form.Group>
        </Col>
        <Col md={12} className="mb-2">
            <Form.Group controlId="classes">
                <Form.Label>Classes</Form.Label>
                <div className="overflow-auto"  style={{height:"76px"}}>
                {classList.map((item) => (
                    <div key={item.value}>
                        <input
                            className="me-2"
                            id={item.value}
                            type="checkbox"
                            name={item.value}
                            checked={values.classes.includes(item.value.toString())}
                            onChange={handleClassesChange}
                        />
                        <label htmlFor={item.value}>{item.label} &nbsp;</label>
                    </div>
                ))}
                </div>
                {errors && errors.classes && <Form.Text className="text-danger">{errors.classes}</Form.Text>}
               
            </Form.Group>
        </Col>
        <Col md={12}>

            <Form.Group controlId="files">
                <Form.Label>Attachment</Form.Label>
                <div className="border px-2 py-2">
                    <Files
                        className='files-dropzone'
                        onChange={handleFilesUploading}
                        multiple
                        maxFileSize={3000000}
                        onError={(error, file) => {
                            toast.warning(error.message)
                        }}
                        maxFiles={2}
                        clickable>
                        <div>
                            <img src={exportIcon} alt="" className="image-responsive" /> Add Attachments
                        </div>
                    </Files>
                    {values.files.length > 0 && (
                        <div className="d-flex justify-content-between flex-wrap overflow-auto"  style={{height:"150px"}}>
                            {console.log(values.files)}
                            {values.files.map(file => (
                                <div className="text-center">
                                    <FileText size={50} />
                                    <div className="file-title text-center" >
                                        {file.name || ""}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {errors && errors.files && <Form.Text className="text-danger">{errors.files}</Form.Text>}
            </Form.Group>
  
        </Col>
    </Row>)
}   