import React, {
    useCallback,
    useState,
    useEffect,
    useRef
} from "react";
import Container from 'react-bootstrap/Container';
import BaseTable from "components/BaseTable.js";
import NavBar from "components/MainNavbar";
import editIcon from "components/Dashboard/assets/edit-icon.svg";    
import resetIcon from "components/Dashboard/assets/list.png"; 
import { fetchAllCleaners, updateCleanerWithoutDispatch, fetchEmailsByCleaners } from "services/cleaner";
import {Row, Col, Navbar, Form, Stack} from 'react-bootstrap'
import { Space, Button } from 'antd';
import CleanerEditModal from "components/Cleaner/CleanerEditModal";
import CleanerEmailModal from "components/Cleaner/CleanerEmailModal";
import CleanerEmailHistory from "components/Cleaner/CleanerEmailHistory"
import InnerLoading from "components/InnerLoading";
import { toast } from "react-toastify";

export default function Cleaner() {
    const cleanerFilterOptions = [
        {
            "label": "All Cleaners",
            "value": "all"
        },
        {
            "label": "Cleaners with Email",
            "value": "has_email"
        },
        {
            "label": "Cleaners without Email",
            "value": "no_email"
        },
    ]
    const [rows, setRows] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [cleanerModal, setCleanerModal] = useState(false);
    const [cleanerEmailModal, setCleanerEmailModal] = useState(false);
    const [currentCleaner, setCurrentCleaner] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [search, setSearch] = useState();
    const [filter, setFilter] = useState(cleanerFilterOptions[0].value);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const searchRef = useRef();
    const cols = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter : true
        },
        { 
            title: 'Email Address',
            dataIndex: 'email',
            sorter : true
        },
        { 
            title: 'District Name',
            dataIndex: 'districtName'
        },
        { 
            title: 'School',
            dataIndex: 'schoolNames',
            render: (row) => {
                if (row && row.length > 0)
                    return <ul>
                        {row.map(schoolName => (<li>{schoolName}</li>))}
                    </ul>
                else
                    return "-"
            }
        },
        {
            title: 'Action',
            key: 'action',
            sorter: false,
            render: (row) => (
              <Space size="middle">
                <Button variant="info" size="sm" onClick={() => handleEdit(row)}>
                    <img src={editIcon} alt="" className="image-responsive" />
                </Button>
                <Button variant="info" size="sm" onClick={() => handleHistory(row)}>
                    <img src={resetIcon} alt="" className="image-responsive" />
                </Button>
              </Space>
            ),
          },
    ]
    const handleEdit = (row) => {
        setCurrentCleaner(row)
        setCleanerModal(true)
    }
    const handleHistory = (row) => {
        setCurrentCleaner(row)
        setShowHistory(true)
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        
        const response = await fetchAllCleaners(page, pageSize, params, search, filter);
  
        setTotalCount(response.count)
        setRows(response.results)
  
        setLoading(false);
    }, [page, pageSize, params, search, filter])

    const handleCleanerSubmit = async (id, values) => {
        const response = await updateCleanerWithoutDispatch(id, values);
        if (response) {
            let index = rows.findIndex(item => item.id == id);
            let newRows = rows;
            newRows[index] = {...newRows[index], ...values}
            setRows(newRows);
            if (selectedRows) {
                fetchSelectedCleaners();
            }
        }
    }



    const handleSelectRows = (newSelectedRows) => {
        setSelectedRows(newSelectedRows);
    }

    const handleFetch = (currentPage, currentPageSize, currentParams) => {
        setPage(currentPage);
        setPageSize(currentPageSize);
        setParams(currentParams);
    }

    const handleFilter = ({target: {value}}) => {
        setFilter(value)
        setPage(1)
        setPageSize(10)
    }

    const handleSearch = () => {
        setSearch(searchRef.current.value)
    }

    const fetchSelectedCleaners = async () => {
        const queryParams={
            "ids": selectedRows,
        }
        const response = await fetchEmailsByCleaners(queryParams)
        setEmails(response);
    }

    useEffect(() => {
        fetchData();
    }, [page, pageSize, params, search, filter])

    useEffect(() => {
        if (selectedRows) {
            fetchSelectedCleaners();
        }
    }, [selectedRows])

    const handleOpenEmailModal = () => {
        if (emails.length > 0)
            setCleanerEmailModal(true)
        else
            toast.warning("Please select at least one cleaner who has email.")
    }

    const handleBack = () => {
        setCurrentCleaner(null);
        setShowHistory(false);
    }
    return (
        <div className="page-content">
            <InnerLoading show={loading} />
            <NavBar />
            {showHistory? 
                <>
                    <Row className="graph-header align-items-center">
                        <Col md={1} xs={4}>
                            <button className="arrow-back-btn" onClick={handleBack}>
                                <Stack direction="horizontal" className="align-items-center">
                                    <i className="fa-solid fa-arrow-left"></i>
                                    <p> Back </p>
                                </Stack>
                            </button>
                        </Col>
                        <Col md={11} xs={8}>
                            <h4>{currentCleaner? currentCleaner.name: "-"} </h4>
                        </Col>
                    </Row>
                    <Container className="mt-4">
                        <CleanerEmailHistory cleaner={currentCleaner} />
                    </Container>
                </>
            :
                <>
                    <Row className="search-row">
                        <Col sm={8} className="d-flex">
                        <Navbar.Brand>Cleaners</Navbar.Brand>
                        <Form.Control
                            ref={searchRef}
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button onClick={handleSearch} variant="secondary" className="me-5 search-items search-button">
                            Search
                        </Button>
                        <Form.Select
                            aria-label="Default select example"
                            className="me-3 search-items"
                            onChange={handleFilter}
                        >
                            {cleanerFilterOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                            ))}
                        </Form.Select>
                        </Col>
                        <Col sm={4} className="d-flex col-invite">
                            <Button variant="link" onClick={() => handleOpenEmailModal()} className="me-2">
                                Send Email
                            </Button>
                        </Col>
                    </Row>
                    
                    <Container className="mt-4">
                        <BaseTable 
                            cols={cols}
                            rows={rows}
                            totalCount={totalCount}
                            getData={handleFetch}
                            handleSelectRows={handleSelectRows}
                            selectedRows={selectedRows}
                        />
                    </Container>
                </>
            
            }
            
            <CleanerEditModal
                open={cleanerModal}
                cleaner={currentCleaner}
                onCloseModal={() => setCleanerModal(false)}
                handleCleanerSubmit={handleCleanerSubmit}
            />
            <CleanerEmailModal 
                open={cleanerEmailModal}
                emails={emails}
                onCloseModal={() => setCleanerEmailModal(false)}
            />
        </div>
    )
    
}