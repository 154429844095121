import React from "react";
import { useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import AddCleanerDropdown from "components/Dashboard/OverviewComponents/Cleaner/AddCleanerDropdown";
export default function RepeatableRoomForm(props) {
    const {values, handleChange, errors, school, handleCleanerSelect} = props;
    const {
        sections: { data: sections},
    } = useSelector(({ section }) => section);

    return (
        <>
        <Row>
            <Col md={12}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                    />
                    {errors && errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                </Form.Group>
            </Col>
            
            <Col md={6}>
                <Form.Group>
                    <Form.Label>Section</Form.Label>
                    <Form.Select
                        aria-label="Section"
                        className="me-3 search-items"
                        name="section"
                        value={values?.section}
                        onChange={handleChange}
                    >
                        <option value="">---------</option>
                        {sections.map((section) => (
                            <option key={section?.id} value={section?.id}>
                                {section?.name}
                            </option>
                        ))}
                    </Form.Select>
                    {errors && errors.section && <Form.Text className="text-danger">{errors.section}</Form.Text>}
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group>
                <div >
                    <AddCleanerDropdown
                        onChange={handleCleanerSelect}
                        school={school}
                        defaultValue={values.cleaner}
                        isStandard={true}
                    />
                </div>
                {errors && errors.cleaner && <Form.Text className="text-danger">{errors.cleaner}</Form.Text>}
            </Form.Group>

            </Col>
        </Row>
        </>
    );
}