import React, {
    useCallback,
    useState,
    useEffect,
    useRef
} from "react";
import Container from 'react-bootstrap/Container';
import BaseTable from "components/BaseTable.js";
import NavBar from "components/MainNavbar"; 
import { fetchTrainingVideoEmailHistory } from "services/cleaner";
import {Row, Col, Navbar, Form } from 'react-bootstrap'
import { Button } from 'antd';
import InnerLoading from "components/InnerLoading";
import { FileText } from 'react-bootstrap-icons';
import {handleDownloadFile} from 'utils/utility';

export default function Cleaner() {

    const [rows, setRows] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const searchRef = useRef();
    const cols = [
        {
            title: 'Date Sent',
            dataIndex: 'sentAt',
            width: '15%',
        },
        { 
            title: 'Cleaners',
            dataIndex: 'cleaners',
            width: '15%',
            render: (row) => {
                if (row && row.length > 0)
                    return <ul>
                        {row.map(cleaner => (<li>{cleaner.email}</li>))}
                    </ul>
                else
                    return "-"
            }
        },
        { 
            title: 'Message',
            dataIndex: 'message',
            width: '25%',
        }, 
        { 
            title: 'Classes',
            dataIndex: 'cleanClasses',
            width: '20%',
            render: (row) => {
                if (row && row.length > 0)
                    return <ul>
                        {row.map(clean_class => (<li>{clean_class.title}</li>))}
                    </ul>
                else
                    return "-"
            }
        },
        { 
            title: 'Attachments',
            dataIndex: 'trainingVideos',
            render: (row) => {
                if (row && row.length > 0)
                    return <div className="d-flex justify-content-between flex-wrap">
                        {
                            row.map(video => (
                                <a className="text-center" width={25} onClick={() => handleDownloadFile(video.file, video.fileName)}> 
                                    <FileText size={50} />
                                    <div className="file-title text-center" >
                                        {video.fileName || ""}
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                else
                    return "-"
            }
        },
        { 
            title: 'Sent By Username',
            dataIndex: 'sentByUsername',
            width: '5%',
        }, 
    ]


    const fetchData = useCallback(async () => {
        setLoading(true);
        
        let queryParams = params || {};
        queryParams = {...queryParams, ...(search && {search: search}),}
        const response = await fetchTrainingVideoEmailHistory(page, pageSize, queryParams);
        setTotalCount(response.count)
                   setRows(response.results)
  
        setLoading(false);
    }, [page, pageSize, params, search])




    const handleFetch = (currentPage, currentPageSize, currentParams) => {
        setPage(currentPage);
        setPageSize(currentPageSize);
        setParams(currentParams);
    }



    const handleSearch = () => {
        setSearch(searchRef.current.value)
    }


    useEffect(() => {
        fetchData();
    }, [page, pageSize, params, search])


    return (
        <div className="page-content">
            <InnerLoading show={loading} />
            <NavBar />
            <Row className="search-row">
                <Col sm={6} className="d-flex">
                    <Navbar.Brand>Email Histories</Navbar.Brand>
                    <Form.Control
                        ref={searchRef}
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                        
                    />
                    <Button onClick={handleSearch} variant="secondary" className="me-5 search-items">
                        Search
                    </Button>
                </Col>
                <Col sm={6} className="d-flex justify-end align-items-center">
                    

                </Col>
            </Row>
            
            <Container className="mt-4">
                <BaseTable 
                    cols={cols}
                    rows={rows}
                    totalCount={totalCount}
                    getData={handleFetch}
                />
            </Container>
        </div>
    )
    
}