import React from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function CleanerForm(props) {
    const {values, handleChange, handleBlur, errors} = props;
    return (<Row>
        <Col md={12}>
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors && errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
            </Form.Group>
        </Col>
        <Col md={12}>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors && errors.name && <Form.Text className="text-danger">{errors.email}</Form.Text>}
            </Form.Group>
        </Col>
    </Row>)
}