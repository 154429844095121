import { Axios } from "api/axios"
import { getHeader, toastMsg } from "utils/utility";
import { getSimplifiedError } from "utils/error";

import {
  resetBlockFlags,
  setBlockLoadingFlag,
  fetchCleanersSuccess,
  fetchCleanersFailure,
  startBlockFlags,
  fetchSchoolCleanersSuccess,
  setBlockFailure,
  addSchoolCleanerSuccess,
  addCleanerSuccess,
  deleteSchoolCleanerSuccess,
  deleteCleanerSuccess,
  updateCleanerSuccess,
  fetchDistrictCleanersSuccess,
  addDistrictCleanerSuccess,
  deleteDistrictCleanerSuccess,
  updateDistrictCleanerSuccess,
} from "features/cleaner/cleanerSlice";
import { NOTIFICATION } from "constants/constants";

export const fetchCleaners = (buildingId="",districtId="") => async (dispatch) => {
  dispatch(resetBlockFlags({'blockType': 'cleaners'}));
  dispatch(setBlockLoadingFlag({'blockType': 'cleaners', loading: true}));
  try {
    let url=`/api/v1/cleaner/`;
    let params = {};
    if (buildingId) {
      params.school = buildingId;
    }
    if (districtId) {
      params.district = districtId;
    }
    const response = await Axios.get(url, {...getHeader(), params: params});
    dispatch(fetchCleanersSuccess(response));
  } catch (error) {
    dispatch(fetchCleanersFailure(getSimplifiedError(error)));
  }
};

export const fetchSchoolCleaners = ({schoolId}) => async (dispatch) => {
  dispatch(startBlockFlags({blockType: 'schoolCleaners'}))
  try {
    const response = await Axios.get(`/api/v1/cleaner/?school=${schoolId}`, getHeader());
    dispatch(fetchSchoolCleanersSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({blockType: 'schoolCleaners', error: getSimplifiedError(error)}))
  }
};
export const fetchDistrictCleaners = ({districtId}) => async (dispatch) => {
  dispatch(startBlockFlags({blockType: 'districtCleaners'}))
  try {
    const response = await Axios.get(`/api/v1/cleaner/?district=${districtId}`, getHeader());
    dispatch(fetchDistrictCleanersSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({blockType: 'districtCleaners', error: getSimplifiedError(error)}))
  }
};

export const addCleaner = (payload) => async (dispatch) => {
  let isAddingExisting = !!payload.id;
  let blockType = isAddingExisting ? 'addExistingCleaner' : 'addCleaner';

  dispatch(resetBlockFlags({blockType}));
  dispatch(setBlockLoadingFlag({blockType, loading: true}));

  try {
    //If we are passing an id, then it means we are adding and existing cleaner
    const url = isAddingExisting ? `/api/v1/add/existing/cleaner/` : `/api/v1/cleaner/`; 
    if (isAddingExisting) {
      payload = {
        ...payload,
        cleaner: payload.id,
      }
    }
    const response = await Axios.post(
      url,
      payload,
      getHeader()
    );
    let cleaner
    if (isAddingExisting) {
      const addedCleaner = await Axios.get(`/api/v1/cleaner/${payload.id}/`, getHeader())
      cleaner = {...addedCleaner, schoolId: payload.school};
    } else {
      cleaner = {...response, schoolId: payload.school}
    }
    if (isAddingExisting) {
      dispatch(addSchoolCleanerSuccess(cleaner));
    } else {
      dispatch(addCleanerSuccess(cleaner))
      dispatch(addDistrictCleanerSuccess(cleaner))
    }
    return response;
  } catch (error) {
    dispatch(setBlockFailure({blockType, error: getSimplifiedError(error)}))
  }
};
export const deleteCleaner = ({cleanerId}) => async (dispatch)  => {
    dispatch(startBlockFlags({blockType: 'deleteCleaner'}))
    try {
      await Axios.delete(`/api/v1/cleaner/${cleanerId}/`, getHeader());
      dispatch(deleteDistrictCleanerSuccess(cleanerId));
      dispatch(deleteSchoolCleanerSuccess(cleanerId));
      dispatch(deleteCleanerSuccess(cleanerId));
    } catch (error) {
      dispatch(setBlockFailure({blockType: 'deleteCleaner', error: getSimplifiedError(error)}))
    }
}
export const updateCleaner = (cleaner) => async (dispatch)  => {
    dispatch(startBlockFlags({blockType: 'updateCleaner'}))
    try {
      const response = await Axios.patch(`/api/v1/cleaner/${cleaner.id}/`, cleaner, getHeader());
      dispatch(updateCleanerSuccess(response));
      dispatch(updateDistrictCleanerSuccess(response));
    } catch (error) {
      dispatch(setBlockFailure({blockType: 'updateCleaner', error: getSimplifiedError(error)}))
    }
}
export const maybeDeleteRoomCleaner = ({cleanerId, schoolId}) => async (dispatch) => {
    try {
      if (!cleanerId) {
        return;
      }
      const fetchRooms = Axios.get(`/api/v1/room/?school=${schoolId}`, getHeader());
      const fetchInspections = Axios.get(`/api/v1/inspection/?school=${schoolId}`, getHeader());
      const [rooms, inspections] = await Promise.all([fetchRooms, fetchInspections])

      //Remove the cleaner from the school entirely 
      //if they are not present in any of the school rooms or inspections.

      let shouldDeleteCleaner = !rooms.some(({cleaner}) => cleaner === cleanerId );
      shouldDeleteCleaner &&= !inspections.some(({cleaner}) => cleaner === cleanerId);
      if (shouldDeleteCleaner) {
        await Axios.delete( `/api/v1/delete/cleaner/school/`, {
          ...getHeader(),
          data: {
            cleaner: cleanerId, school: schoolId
          }});
        dispatch(deleteSchoolCleanerSuccess(cleanerId));
      }
    } catch (error) {
      console.log(error)
    }
}

export const fetchAllCleaners = async (page=1, pageSize=10, queryParams=null, search=null, filter=null) => {

  try {
    let url= `/api/v1/cleaner-pagination/`;
    let params = {
      ...(page && {page: page}),
      ...(pageSize && {page_size: pageSize}),
      ...(queryParams && {...queryParams}),
      ...(search && {search: search}),
      ...(filter && {cleaner_email: filter})
    };

    const response = await Axios.get(url, {...getHeader(), params: params});

    return response
    
  } catch (error) {
    console.log(error)
  }
};

export const updateCleanerWithoutDispatch = async (id, payload) =>  {
  try {
    const response = await Axios.patch(`/api/v1/cleaner/${id}/`, payload, getHeader());
    toastMsg("This value has been updated successfully.")
    return response
  } catch (error) {
    toastMsg(getSimplifiedError(error).toString(), NOTIFICATION.error)
  }
}

export const fetchEmailsByCleaners = async (params) => {

  try {
    let url= `/api/v1/cleaner-pagination/emails-by-ids/`;

    const response = await Axios.get(url, {...getHeader(), params: params});

    return response
    
  } catch (error) {
    console.log(error)
  }
};

export const sendTrainingEmail = async (formData) => {
  try {
    const response = await Axios.post(`/api/v1/video/send-training-email/`, formData, getHeader());
    toastMsg("Training videos have been sent to selected cleaners.")
    return response
  } catch (error) {
    toastMsg("There is an error on your request. Please try again later.", NOTIFICATION.error)
  }
}

export const fetchTrainingVideoEmailHistory = async (page=1, pageSize=10, queryParams=null) => {
  let params = {
    ...(page && {page: page}),
    ...(pageSize && {page_size: pageSize}),
    ...(queryParams && {...queryParams}),
  };
  try {
    const response = await Axios.get(`/api/v1/video/training-video-email-history/`, {...getHeader(), params: params});
   
    return response
  } catch (error) {
    toastMsg("There is an error on your request. Please try again later.", NOTIFICATION.error)
  }
}

export const fetchCleanClass = async () => {
  try {
    const response = await Axios.get(`/api/v1/video/clean-class/`, getHeader());

    return response
  } catch (error) {
    toastMsg("There is an error on your request. Please try again later.", NOTIFICATION.error)
  }
}