import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Button, Modal, Row, Col, Container} from 'react-bootstrap';
import { fetchCleanClass, sendTrainingEmail } from "services/cleaner";

import { useFormik  } from 'formik';
import * as Yup from 'yup';
import CleanerEmailForm from './CleanerEmailForm';

export default function CleanerEditModal({
    open,
    onCloseModal,
    emails,
  }) {

    const startValues = {
        "emails": '',
        "message": "",
        "classes": [],
        "files": []
    }
    const [isLoading, setIsLoading] = useState(false);
    const [classList, setClassList] = useState([]); 
    const initialValues = useMemo(() => {
        if (emails) {
            return {
                ...startValues,
                "emails": emails,
            }
        } else {
            return startValues
        }
    }, [emails])
      
    const validationSchema = Yup.object().shape({
        message: Yup.string().required("This field is required."),
        classes: Yup.array().min(1, "At least one class is required").required(),
        // files: Yup.array().min(1, "At least one file is required").required()
    });

    const handleFetchCleanClass = useCallback(async () => {
        const response = await fetchCleanClass();
    
        setClassList(response.map(item => ({label: item.title, value: item.id})))
    }, [open]) 

    useEffect(() => {
        handleFetchCleanClass()
    }, [])

    const handleSubmit = async (values) => {
        setIsLoading(true);

        const formData = new FormData();
        values.files.forEach((file) => {
            formData.append("files", new Blob([file], { type: file.type }), file.name || 'file')
        })
        values.emails.map(email => {
            formData.append("emails", email)
        })
        values.classes.map(item => {
            formData.append("clean_classes", item)
        })

        formData.append("message", values.message);

        await sendTrainingEmail(formData)
        handleClose()
        setIsLoading(false); 
    }
  
      const formik = useFormik({
          initialValues: initialValues,
          validationSchema: validationSchema,
          enableReinitialize: true,
          validateOnChange: false,
          onSubmit: handleSubmit
      });
  
      const handleClickSubmit = () => {
        formik.handleSubmit();
      }
      const handleClose = () => {
          onCloseModal();
          formik.resetForm();
      }
      
      const handleClassesChange = (e) => {
        const { checked, name } = e.target;

        if (checked) {
            formik.setFieldValue("classes", [...formik.values.classes, name]);
        } else {
            formik.setFieldValue(
                "classes",
                formik.values.classes.filter((v) => v !== name)
            );
        }
      }

      const handleFilesUploading = async (files) => {
        const uploadedFiles = [];

        formik.setFieldValue("files", files);
        return uploadedFiles;
      };
      return (
          <Modal
              onHide={onCloseModal}
              show={open}
             size="sm" 
             className="modal-sm modal-md modal-lg modal-xl"
              backdrop="static"
              centered
          >
          <Modal.Header className="mb-2" >
              <Modal.Title className="text-capitalize">
                Send Email
              </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3 scrollable-modal-body">
  
                  <Container fluid>
                      <Row> 
                          <Col md={12}>
                              <form onSubmit={formik.handleSubmit}>
                                  <CleanerEmailForm
                                      values={formik.values}
                                      handleClassesChange={handleClassesChange}
                                      handleFilesUploading={handleFilesUploading}
                                      handleChange={formik.handleChange}
                                      handleBlur={formik.handleBlur}
                                      touched={formik.touched}
                                      errors={formik.errors}
                                      classList={classList}
                                  />
                              </form>
                          </Col>
                      </Row>
                  </Container>
                  <Modal.Footer className="p-0 pt-3 justify-content-between">
                      <Button variant="light" className="me-0 btn-unset-height" onClick={() => handleClose()} disabled={isLoading}>Close</Button> 
                      <Button onClick={handleClickSubmit} disabled={isLoading}>Submit</Button> 
                  </Modal.Footer> 
              </Modal.Body>
          </Modal>
      );
  }
  