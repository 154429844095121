import React, { useEffect, useState, useMemo, useCallback } from "react";

import {
    fetchRepeatableRoomTemplate,
    updateRepeatableRoomTemplate,
} from "services/section";
import {
    Alert,
    Container,
    Col,
    Row,
    Button,
    Modal
} from "react-bootstrap";
import { convertToHoursMinutesSeconds } from "utils/utility";
import RoomFeaturesTable from "components/RoomFeatures/RoomFeaturesTable";
import RepeatableRoomForm from "components/RepeatableRoomTemplate/RepeatableRoomTemplateForm";
import RepeatableRoomFeatureForm from "components/RepeatableRoomTemplate/RepeatableRoomFeatureTemplateForm";
import { useFormik  } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
    INSPECTION_ITEM_PASS_OR_FAIL_TYPE,
    INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME,
    INSPECTION_ITEM_NUMERICAL_TYPE_NAME,
    REPEATABLE_ROOM_TYPE
  } from "utils/constants"
import RoomItemsForm from "components/Inspections/RoomItemsForm";
import { toast } from "react-toastify";
export default function RepeatableRoomModal({
  open,
  onCloseModal,
  section
}) {
    const FEATURE_TYPE = "feature_action"
    const ROOM_TYPE = "room_action"
    const QUESTION_TYPE = "question_action"
    const startRoomValues = {
        "name": ""
    }
    const startRoomFeatureValues = {
        "name": "",
        "quantity": 0,
        "timeInMinutes": 0
    }
    const [isLoading, setIsLoading] = useState(false);
    const [initialRoomValues, setInitialRoomValues] = useState(startRoomValues);
    const [initialRoomFeatureValues, setInitialRoomFeatureValues] = useState(startRoomFeatureValues);
 
    const [roomFeatures, setRoomFeatures] = useState([]);
    const [actionType, setActionType] = useState(ROOM_TYPE);
    const [roomId, setRoomId] = useState(null);
    const [featureId, setFeatureId] = useState(null);

    const [questions, setQuestions] = useState([]);
    const [totalWorkload, setTotalWorkLoad] = useState("");
    const [changed, setChanged] = useState(false);
    const roomValidationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required.'),
    });
    const featureValidationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required.'),
        quantity: Yup.number().required('This field is required.'),
        timeInMinutes: Yup.number().required('This field is required.'),
    });


    const handleFetchData = useCallback(async () => {
        setIsLoading(true);
        if (section) {
            const response = await fetchRepeatableRoomTemplate(section.id);
            if (!_.isEmpty(response) && response.length > 0) {
                setRoomId(response[0].id)
                setInitialRoomValues(response[0]);
                setRoomFeatures(response[0].repeatableRoomFeatures || []);
                setQuestions(response[0].repeatableInspectionItems || []);
            }
        }
        setIsLoading(false)
    }, [section]);

    const handleTotalWorkLoad = () => {
        let totalMins = 0;
        roomFeatures.forEach((feature) => {
            totalMins +=  ((feature.timeInMinutes || 0)*(feature.quantity||0));
        });

        setTotalWorkLoad(convertToHoursMinutesSeconds(totalMins*60))
    }
    useEffect(() => {
        handleFetchData();
        handleTotalWorkLoad();
    }, [])
    useEffect(() => {
        handleTotalWorkLoad()
    }, [roomFeatures])


    const handleRoomSubmit = async (values) => {
        if (questions.length == 0 || roomFeatures.length == 0) {
            toast.warning("At least one of inspection items and features are required.")
        } else {
            let roomFeaturesPayload = []
            roomFeatures.map(item => {
                if (typeof item.id == "string" && item.id.toString().startsWith("new")) {
                    delete item.id;
                }
                roomFeaturesPayload.push(item);
            })

            let payload =  {
                ...values,
                section: section.id,
                repeatableRoomFeatures: roomFeaturesPayload,
                repeatableInspectionItems: questions
            }

            await updateRepeatableRoomTemplate(roomId, payload);

            handleClose()
    
        }
    }
    
    const roomFormik = useFormik({
        initialValues: initialRoomValues,
        validationSchema: roomValidationSchema,
        enableReinitialize: true,
        onSubmit: handleRoomSubmit
    });
    
    const handleClickSubmit = () => {
        if (actionType == FEATURE_TYPE) {
            featureFormik.handleSubmit();
        } 
        if (actionType == ROOM_TYPE) {
            roomFormik.handleSubmit();
        }
    }
    const handleClose = () => {
        if (actionType == FEATURE_TYPE || actionType == QUESTION_TYPE) {
            setActionType(ROOM_TYPE)
        } else {
            onCloseModal();
        }
    }

    // Feature Edit functions
    const handleFeatureSubmit = (values) => {
        let index = roomFeatures.findIndex(item => item.id == featureId);
        let timeInSeconds = values?.timeInMinutes * 60

        if (index == -1) {
            setRoomFeatures(
                [
                    ...roomFeatures,
                    {
                        id: featureId,
                        ...values,
                        timeInSeconds,
                        
                    }
                ]
            )
        } else {
            setRoomFeatures(prevFeatures => {
                const newFeatures = [...prevFeatures];
                newFeatures[index] = {
                    ...newFeatures[index],
                    ...values,
                    timeInSeconds
                };
                return newFeatures;
            });
        }
        setActionType(ROOM_TYPE);
        setChanged(true);
    }
    const handleEditFeature = (feature=null) => {
        if (feature) {
            setFeatureId(feature.id)
            setInitialRoomFeatureValues(feature);
        } else {
            setFeatureId("new"+roomFeatures.length+1)
            setInitialRoomFeatureValues(startRoomFeatureValues);
            featureFormik.handleReset();
        }
        setActionType(FEATURE_TYPE);
    }
    const handleRemoveFeature = (feature) => {
        let newRoomFeatures = roomFeatures.filter(item => item.id != feature.id);
        setRoomFeatures(newRoomFeatures);
    }
    
    const featureFormik = useFormik({
        initialValues: initialRoomFeatureValues,
        validationSchema: featureValidationSchema,
        enableReinitialize: true,
        onSubmit: handleFeatureSubmit
    });

    // Question edit functions
    const makeInspectionItem = (type) => ({
      id: null,
      item: '',
      itemType: type,
      itemTypeName: type == INSPECTION_ITEM_PASS_OR_FAIL_TYPE? INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME: INSPECTION_ITEM_NUMERICAL_TYPE_NAME,
      roomType: null,
      edited: false,
    });

    const handleAddQuestion = (type=INSPECTION_ITEM_PASS_OR_FAIL_TYPE) => {
        setQuestions([...questions, makeInspectionItem(type)]);
        setChanged(true);
    }
    const handleRemoveQuestion = (idx) => (e) => {
        setQuestions(questions.filter((_, i) => i !== idx));
        setChanged(true);
    }
    const onQuestionInputChange = (idx) => ({ target: { value } }) => {
        questions[idx].item = value;
        questions[idx].edited = true;
        setQuestions(questions.slice())
    }
    const handleSaveQuestion = async () => {
        setActionType(ROOM_TYPE)
        setChanged(true);
    };

    return (
        <Modal
            onHide={onCloseModal}
            show={open}
             centered
          size="xl"
            backdrop="static"
        >
        <Modal.Header className="mb-2 justify-content-center" >
            <Modal.Title className="text-capitalize">
                {actionType == FEATURE_TYPE ? 
                "Edit Repeatable Room Template Feature Details":
                "Edit Repeatable Room Template Details"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 scrollable-modal-body">
            {actionType == FEATURE_TYPE &&
                <form  onSubmit={featureFormik.handleSubmit}>
                    <RepeatableRoomFeatureForm 
                        values={featureFormik.values}
                        handleChange={featureFormik.handleChange}
                        touched={featureFormik.touched}
                        errors={featureFormik.errors}
                        handleBlur={featureFormik.handleBlur}
                    />
                </form >
            }
            {actionType == ROOM_TYPE &&
                <Container fluid>
                    <Row> 
                    {changed && <Col md={12}>
                        <Alert
                            variant="warning"
                        >
                            <Alert.Heading></Alert.Heading>
                            <p><b>Warning</b></p>
                            <p>You have unsaved changes. Please click "Submit" button before close modal.</p>
                        </Alert>
                    </Col>}
                    <Col md={12}>
                        <form onSubmit={featureFormik.handleSubmit}>
                            <RepeatableRoomForm 
                                values={roomFormik.values}
                                handleChange={roomFormik.handleChange}
                                errors={roomFormik.errors}
                                roomTypeName={REPEATABLE_ROOM_TYPE}
                                totalWorkLoad={totalWorkload}
                            />
                        
                        </form>
                    </Col>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <h5> Features </h5>
                        <div className="d-flex">
                            <Button
                                variant="light"
                                size="sm"
                                className="btn-unset-height me-2"
                                onClick={() => handleEditFeature()}
                                >
                                Add Feature
                            </Button>
                            <Button
                                variant="light"
                                size="sm"
                                className="btn-unset-height"
                                onClick={() => setActionType(QUESTION_TYPE)}
                            >
                                Edit Inspection Items
                            </Button>
                        </div>
                        
                    </Col>
                    <Col md={12} className="mt-2">
                        <RoomFeaturesTable
                            data={roomFeatures}
                            editFeatureHandler={handleEditFeature}
                            removeFeatureHandler={handleRemoveFeature}
                        />
                    </Col>
                    </Row>
                </Container>
            }
            {actionType == QUESTION_TYPE && 
                <RoomItemsForm
                    roomName={''}
                    questions={questions}
                    startEditingRoom={false}
                    canEditRoom={false}
                    isUpdatingForm={isLoading}
                    isLoadingQuestion={isLoading}
                    handleBackClick={() => setActionType(ROOM_TYPE)}
                    handleSaveRoomClick={handleSaveQuestion}
                    handleAddQuestionClick={handleAddQuestion}
                    handleRemoveQuestionClick={handleRemoveQuestion}
                    handleQuestionInputChange={onQuestionInputChange}
                />
            }
                {actionType != QUESTION_TYPE &&
                    <Modal.Footer className="p-0 pt-3 justify-content-between">
                        <Button variant="light" className="btn-unset-height" onClick={() => handleClose()} disabled={isLoading}>Close</Button> 
                        <Button onClick={handleClickSubmit} disabled={isLoading}>Submit</Button> 
                    </Modal.Footer> 
                }
            </Modal.Body>
        </Modal>
    );
}
