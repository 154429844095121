import React, { useMemo, useState } from "react";
import {Button, Modal, Row, Col, Container} from 'react-bootstrap';

import { useFormik  } from 'formik';
import * as Yup from 'yup';
import CleanerForm from './CleanerForm';

export default function CleanerEditModal({
    open,
    onCloseModal,
    cleaner,
    handleCleanerSubmit
}) {

      const startCleanerValues = {
          "name": "",
          "email": ""
      }
      const [isLoading, setIsLoading] = useState(false);

        const initialCleanerValues = useMemo(() => {
        if (cleaner) {
            return {
                "name": cleaner.name,
                "email": cleaner.email || ""
            }
        } else {
            return startCleanerValues
        }
      }, [cleaner])
      
      const cleanerValidationSchema = Yup.object().shape({
          name: Yup.string().required('This field is required.'),
          email: Yup.string().required("This field is required.")
      });
  
      const handleSubmit = async (values) => {
          setIsLoading(true);
          await handleCleanerSubmit(cleaner.id, values)
          handleClose()
          setIsLoading(false); 
      }
  
      const cleanerFormik = useFormik({
          initialValues: initialCleanerValues,
          validationSchema: cleanerValidationSchema,
          enableReinitialize: true,
          onSubmit: handleSubmit
      });
  
      const handleClickSubmit = () => {
        cleanerFormik.handleSubmit();
      }
      const handleClose = () => {
          onCloseModal();
      }
      
      return (
          <Modal
              onHide={onCloseModal}
              show={open}
              size="lg"
          >
          <Modal.Header className="mb-2 justify-content-center" >
              <Modal.Title className="text-capitalize">
                  Edit Cleaner Details
              </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3">
  
                  <Container fluid>
                      <Row> 
                          <Col md={12}>
                              <form onSubmit={cleanerFormik.handleSubmit}>
                                  <CleanerForm
                                      values={cleanerFormik.values}
                                      handleChange={cleanerFormik.handleChange}
                                      handleBlur={cleanerFormik.handleBlur}
                                      touched={cleanerFormik.touched}
                                      errors={cleanerFormik.errors}
                                  />
                              </form>
                          </Col>
                      </Row>
                  </Container>
                  <Modal.Footer className="p-0 pt-3 justify-content-between">
                      <Button variant="light" className="me-0 btn-unset-height" onClick={() => handleClose()} disabled={isLoading}>Close</Button> 
                      <Button onClick={handleClickSubmit} disabled={isLoading}>Submit</Button> 
                  </Modal.Footer> 
              </Modal.Body>
          </Modal>
      );
  }
  