import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoomsSelection from "./RoomsSelection";
import { fetchSections } from "services/section";
import InnerLoading from "components/InnerLoading";
import { REPEATABLE_ROOM_TYPE } from "utils/constants";

export function InspectionRoomsList({
    schoolId,
    search,
    selectedRoom,
    rooms = [],
    loading = false,
    ListItemRightSideComponent = null,
    handleListItemClick,
    groupInspections
}) {
    const dispatch = useDispatch();
    const {
        sections: { data: sectionsData, success: sectionSuccess, loading: sectionsLoading },
    } = useSelector(({ section }) => section);
    const [sortedRooms, setSortedRooms] = useState([]);
    const getRoomInspection = (inspections, roomId) => {
        return inspections.find(({ room }) => roomId === room);
    }

    useEffect(() => {
        if (!sectionSuccess) {
            dispatch(fetchSections(schoolId));
        }
    }, [])

    useEffect(() => {
        if (sectionSuccess) {
            const sectionNames = sectionsData.reduce((sections, section) => {
                sections[section.id] = section.name;
                return sections;
            }, {});
            let filteredRooms = rooms.filter((room) => {
                if (groupInspections) {
                    const inspection = getRoomInspection(groupInspections, room.id);
                    if (!inspection && room.roomTypeName == REPEATABLE_ROOM_TYPE) {
                        return false;
                    }
                }
                return true;
            })
            let sorted = filteredRooms.map((room) => {
                return { ...room, sectionName: sectionNames[room.section] }
            });
            sorted = sorted.sort((roomA, roomB) => {
                var roomASectionName = roomA.sectionName;
                var roomBSectionName = roomB.sectionName;
                if (roomASectionName === roomBSectionName) {
                    return roomA.name.localeCompare(roomB.name);
                }
                return roomASectionName.localeCompare(roomBSectionName)
            });
            setSortedRooms(sorted);
        }

    }, [sectionSuccess, rooms])
    return (
        <div className="position-relative">
            <InnerLoading show={loading || sectionsLoading} />
            {sortedRooms.length === 0 && (!loading && !sectionsLoading) && (
                <p className="d-flex align-items-center justify-content-center p4">
                    No rooms to show
                </p>
            )}
            {sortedRooms.length > 0 && (
                <RoomsSelection
                    search={search}
                    schoolId={schoolId}
                    selectedRoom={selectedRoom}
                    rooms={sortedRooms}
                    handleRoomClick={handleListItemClick}
                    RightSideComponent={ListItemRightSideComponent}
                />
            )}
        </div>
    )

}