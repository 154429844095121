import React from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function RepeatableRoomForm(props) {
  const {values, handleChange, roomTypeName, totalWorkLoad, errors} = props;

  return (
    <>
      <Row>
        <Col md={12}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
              />
              {errors && errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
            </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="room_type_name">
            <Form.Label>Room Type Name</Form.Label>
            <p>{roomTypeName}</p>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="total_work_load">
            <Form.Label>Total Workload</Form.Label>

            <p>{totalWorkLoad} mins</p>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
