import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
export default function BaseTable({
  cols=[],
  rows=[],
  totalCount=0,
  getData,
  handleSelectRows=null,
  selectedRows=[]
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedRows);
  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys) {
      setSelectedRowKeys(newSelectedRowKeys);
      if (handleSelectRows) {
        handleSelectRows(newSelectedRowKeys)
      }
    }
    
  };
  
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const params = {};

    if (sorter.hasOwnProperty("column")) {
      params.ordering = `${sorter.order=="descend"? "-":""}${sorter.field}`;
    }
  
    getData(pagination.current, pagination.pageSize, params);
  };

  useEffect(() => {
    getData()
  },[])
  return (
    <>
      <Table 
          rowKey={record => record.id}
          rowSelection={handleSelectRows?rowSelection:null}
          columns={cols}
          dataSource={rows} 
          onChange={handleChange}
          pagination={{
              showSizeChanger: true,
              total: totalCount, // total count returned from backend
              align: "center"
          }}
      />
    </>
  );
};

