import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import InviteMagicLink from "../pages/InviteMagic";
import Login from "../pages/Login";
import Overview from "../pages/Overview";
import DistrictSchools from "pages/District/DistrictSchools";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/Signup";
import MagicLink from "../pages/VerifyMagic";
import DefaultRoomsPage from "pages/DefaultRooms";
import { RoomForm } from "pages/DefaultRooms/RoomForm";
import RoomList from "pages/DefaultRooms/RoomList";
import Profile from "pages/Profile";
import InspectReport from "pages/InspectReport/InspectReport";
import InspectReportGraph from "pages/InspectReportGraph/InspectReportGraph";
import Settings from "pages/Settings/Settings";
import PrintInspectionReport from "pages/PrintReport/PrintInspectionReport";
import WorkLoad from "pages/WorkLoad/WorkLoad";
import Cleaner from "pages/Cleaner";
import EmailHistory from "pages/EmailHistory"

function PrivateRoutes({ isAllowed = false, redirectTo = "" }) {
  const { isAuthenticated } = useSelector(({ auth }) => auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (!isAllowed) {
    return <Navigate to={redirectTo ? redirectTo : "/403"} replace />;
  }
  return <Outlet />;
}
function AppRoutes() {
  const { isAuthenticated, data: userData } = useSelector(({ auth }) => auth);

  const role = userData?.user?.role;

  const isSuperAdmin = role === "super_admin";
  const isDistrictAdmin = role === "district_admin";
  const isInspector = role === "inspector";

  const isAllowedOnAdminView = isAuthenticated && isSuperAdmin;
  const isAllowedOnDistrictView =
    isAllowedOnAdminView || (isAuthenticated && isDistrictAdmin);
  const isAllowedOnSchoolView =
    isAllowedOnDistrictView || (isAuthenticated && isInspector);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/verify" element={<MagicLink />} />
      {/* <Route path="/login/verify/invite" element={<MagicLink />} /> */}
      <Route path="/login/verify/invite" element={<InviteMagicLink />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route element={<PrivateRoutes isAllowed={isAllowedOnAdminView} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/defaultRooms" element={<DefaultRoomsPage />}>
          <Route path="" element={<RoomList />} />
          <Route path="add" element={<RoomForm />} />
          <Route path="edit/:roomTypeId" element={<RoomForm />} />
        
        </Route>
      </Route>
      <Route element={<PrivateRoutes isAllowed={isAllowedOnDistrictView} />}>
        {/* private routes here */}
        <Route path="/workload" element={<WorkLoad/>}/>
        <Route path="/users" element={<Home />} />
        <Route path="/reports" exact element={<InspectReport/>} />
        <Route path="/reports/:pk/:type" element={<InspectReportGraph />} />
        <Route path="/districts/:districtId" element={<DistrictSchools />} />
      </Route>
      <Route element={<PrivateRoutes isAllowed={isAllowedOnSchoolView} />}>
        <Route path="/schools/:schoolId" element={<Overview />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/printInspection" exact element={<PrintInspectionReport/>} />
      </Route>
      <Route path="/cleaner" exact element={<Cleaner/>} />
      <Route path="/email-histories" exact element={<EmailHistory />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/403" element={<PageAccessDenied />} />
    </Routes>
  );
}

function PageNotFound() {
  return (
    <div className="d-flex vw-100 vh-100 align-items-center justify-content-center flex-column">
      <h1 className="display-1 text-primary ">404</h1>
      <h2>Page not found</h2>
    </div>
  );
}
function PageAccessDenied() {
  return (
    <div className="d-flex vw-100 vh-100 align-items-center justify-content-center flex-column text-center">
      <h1 className="display-1 text-primary">403</h1>
      <h2>Your account does not have access to this page</h2>
    </div>
  );
}

export default AppRoutes;
