import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InnerLoading from "components/InnerLoading";
import { deleteRoomType, fetchRoomTypes } from "services/inspection";
import { useNavigate } from "react-router-dom";
import BaseModal from "components/BaseModal";
import { toast } from "react-toastify";
import { resetBlock } from "features/inspection/inspectionSlice";
import RoomFeatureForm from "components/RoomFeatures/RoomFeature";
import {  convertToHoursMinutesSeconds } from "utils/utility";
function RoomList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roomFeature, setRoomFeature] = useState({
    isVisible: false,
    id: "",
    name: "",
  });
  const toggleRoomFeature = () => {
    setRoomFeature((prev) => ({
      ...prev,
      isVisible: !prev.isVisible,
    }));
  };
  const {
    deleteRoomType: {
      success: deleteSuccess,
      error: deleteError,
      loading: deleteLoading,
    },
    roomTypes: {
      success: typesSuccess,
      loading: typesLoading,
      data: types,
    },
  } = useSelector(({ inspection }) => inspection);

  useEffect(() => {
    if (!typesSuccess) {
      dispatch(fetchRoomTypes(true));
    }
    return () => {
      dispatch(resetBlock({ blockType: "deleteRoomType" }));
    };
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Room type succesfully deleted");
      setOpenDeleteModal(false);
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Room could not be type deleted");
    }
  }, [deleteError]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState({});

  const handleEditType = (type) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/defaultRooms/edit/${type.id}`, {
      state: {
        roomType: type,
      },
    });
  };
  const handleDeleteType = (e) => {
    dispatch(deleteRoomType({ roomType: selectedRoomType }));
  };
  const handleCloseDelete = (e) => {
    setOpenDeleteModal(false);
  };
  const handleOpenDeleteType = (type) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDeleteModal(true);
    setSelectedRoomType(type);
  };
  return (
    <>
      <nav
        className="navbar position-relative navbar-light justify-content-between navbar-container"
        style={{ zIndex: 100 }}
      >
        <div className="container-fluid">
          <div className="ms-auto">
            <button
              className="btn btn-outline-secondary d-flex align-items-center shadow-none"
              onClick={()=>  setRoomFeature((prev) => ({
                ...prev,
                isVisible: !prev.isVisible,
                name:"",
                id:""
              }))}
            >
              <i className="fa-solid fa-plus me-1"></i>
              New Room Type
            </button>
          </div>
        </div>
      </nav>
      <div className="container-fluid pt-3 app-main-content overflow-auto">
        <div className="row">
          <div className="position-relative">
            <InnerLoading show={typesLoading} />
            {types.length === 0 && !typesLoading && (
              <p className="d-flex align-items-center justify-content-center mt-4 p4">
                No rooms to show
              </p>
            )}
          </div>
          {types.length > 0 && (
            <div className="col-12">
              <ol className="nav flex-column numbered-list list-group list-group-flush">
                {types.map((type) => (
                  <li
                    key={`roomType${type.id}`}
                    className="d-flex list-group-item align-items-center col-12 col-lg-6 nav-item"
                  >
                    <div className="list-label">{type.name} <span className="text-secondary">&nbsp; &nbsp; - Work Time: </span> <span>{convertToHoursMinutesSeconds((type?.workloadInSeconds||0))} </span></div>
             
                    
                    <div className="room-actions ms-auto d-flex align-items-center">
                      <button
                        className="mx-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                        onClick={() =>
                          setRoomFeature((prev) => ({
                            ...prev,
                            isVisible: true,
                            ...type,
                          }))
                        }
                      >
                        <i className="fa-sharp fa-solid fa-pen"></i>
                      </button>
                      <button
                        className="mx-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                        onClick={handleEditType(type)}
                      >
                        <i class="fas fa-question"></i>
                      </button>
                      <button
                        className="ml-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                        onClick={handleOpenDeleteType(type)}
                      >
                        <i className="fa-sharp fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      </div>
      {openDeleteModal && (
        <BaseModal
          onConfirm={handleDeleteType}
          onClose={handleCloseDelete}
          show={openDeleteModal}
          title="Delete Room"
          formLoading={deleteLoading}
          content={
            <p>
              Are you sure you want to delete room type&nbsp;
              <strong>{selectedRoomType.name}</strong>? This action cannot be
              undone.
            </p>
          }
        />
      )}
      {roomFeature?.isVisible && (
        <RoomFeatureForm
          open={roomFeature?.isVisible}
          onCloseModal={()=>{
            toggleRoomFeature()}}
           id={roomFeature?.id}
           name={roomFeature?.name}
           workloadInMinutes={roomFeature?.workloadInMinutes||0}
        />
      )}
    </>
  );
}

export default RoomList;
