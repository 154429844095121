import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InspectionForm from "./InspectionForm";
import {
  resetBlock,
  startInspection,
  stopInspection,
} from "features/inspection/inspectionSlice";
import SidebarLayout from "components/SidebarLayout";
import { InspectionRoomsList } from "./InspectionRoomsList";
import { setRooms } from "features/inspection/inspectionSlice";
import { fetchRooms } from "services/school";

function getRoomInspection(inspections, roomId) {
    return inspections.find(({ room }) => roomId === room);
}

const StartInspection = ({
  school,
  isInspector,
  isDistrictAdmin,
  onOpenDeleteInspection,
  groupInspections,
}) => {
  const dispatch = useDispatch();
  const sidebarLayoutRef = useRef();
  const {
    rooms: {
      data: roomsData = [],
      success: roomsSuccess,
      loading: roomsLoading,
      error: roomsError,
    },
  } = useSelector(({ school }) => school);

  const {
    selectedInspectionsGroup: { data: selectedGroup },
    createInspection: { loading: createInspectionLoading, success: createInspectionSuccess },
    updateInspection: { loading: updateInspectionLoading, success: updateInspectionSuccess },
    deleteInspection: { loading: deleteInspectionLoading, success: deleteInspectionSuccess},
    inspection: { inspectedRoom },
    inspectionsGroupRooms: { data: inspectionsGroupRooms },
  } = useSelector(({ inspection }) => inspection);

  const user = useSelector(({ auth }) => auth.data?.user);

  const [inspectionScore, setInspectionScore] = useState({
    result: 0,
    totalItems: 0,
  });
  const [roomInspection, setRoomInspection] = useState();
  const [canSaveInspection, setCanSaveInspections] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!roomsSuccess) {
      dispatch(fetchRooms({ schoolId: school.id }));
    }
    return () => {
      dispatch(resetBlock({ blockType: "createInspection" }));
      dispatch(resetBlock({ blockType: "updateInspection" }));
      dispatch(resetBlock({ blockType: "deleteInspection" }));
    };
  }, []);

  useEffect(() => {
    if (inspectedRoom) {
      let roomInspection = getRoomInspection(groupInspections, inspectedRoom.id);
      setRoomInspection(roomInspection);
    }
  }, [inspectedRoom, groupInspections]);
  

  useEffect(() => {
    if (roomInspection) {
      if (roomInspection.inspectedBy === user.id) {
        setCanSaveInspections(true);
      } else {
        setCanSaveInspections(false);
      }
    } else {
      setCanSaveInspections(true);
    }
  }, [roomInspection]);

  useEffect(() => {
    if (deleteInspectionSuccess && roomsSuccess && !selectedGroup?.isTodays) {
      dispatch(setRooms(roomsData));
    }
  }, [selectedGroup, deleteInspectionSuccess, roomsSuccess]);

  useEffect(() => {
    if (selectedGroup?.isTodays) {
      return;
    }
    if (inspectionsGroupRooms.length === 0) {
      dispatch(stopInspection());
    } else  {
      dispatch(startInspection(inspectionsGroupRooms[0]))
    }
  }, [inspectionsGroupRooms, selectedGroup]);

  const handleRoomClick = (room) => () => {
    dispatch(startInspection(room));
    sidebarLayoutRef.current.toggleShowDetails();
  };

  function RoomListItemRightSideComponent({ room }) {
    let inspection = getRoomInspection(groupInspections, room.id);
    let scoreText = "N/A";

    if (room.id === inspectedRoom.id) {
      scoreText = `${inspectionScore.score}/${inspectionScore.totalItems}`;
    } else if (inspection !== undefined) {
      scoreText = `${inspection.score}/${inspection.totalItems}`;
    }
    return (
      <div className="d-flex align-items-center">
        {inspection && inspection.inspectedBy === user.id && (
          <button
            className="ms-2 me-3 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
            onClick={onOpenDeleteInspection(inspection)}
            title="Delete this inspection"
          >
            <i className="fa-sharp fa-solid fa-trash"></i>
          </button>
        )}
        <div className="d-flex flex-column justify-content-center justify-content-center">
          <span className="d-block mb-0">Score</span>
          <b className="h4 mb-0">{scoreText}</b>
        </div>
      </div>
    );
  }
  return (
    <SidebarLayout
      ref={sidebarLayoutRef}
      mainContentClassName="pt-4 pt-md-0"
      sidebarClassName="pt-4 pt-md-0"
      smShowDetail={inspectedRoom?.id}
      sidebar={
        <InspectionRoomsList
          canSaveInspection={canSaveInspection}
          inspectionScore={inspectionScore}
          setInspectionScore={setInspectionScore}
          loading={
            createInspectionLoading 
            || updateInspectionLoading 
            || deleteInspectionLoading
            || loading
          }
          selectedRoom={inspectedRoom}
          schoolId={school?.id}
          rooms={inspectionsGroupRooms}
          handleListItemClick={handleRoomClick}
          ListItemRightSideComponent={RoomListItemRightSideComponent}
          groupInspections={groupInspections}
        />
      }
      mainContent={
        <div className="tabs-inner-block">
          {inspectedRoom?.id && (
            <InspectionForm
              school={school}
              inspectionScore={inspectionScore}
              setInspectionScore={setInspectionScore}
              canSaveInspection={canSaveInspection}
              selectedRoom={inspectedRoom}
              roomInspection={roomInspection}
              isInspector={isInspector}
              isDistrictAdmin={isDistrictAdmin}
              setRoomListLoading={setLoading}
              onOpenDeleteInspection={onOpenDeleteInspection}
            />
          )}
        </div>
      }
    />
  );
};

export default StartInspection;
