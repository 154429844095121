import React, { useState } from "react";
import {
    createRepeatableInspection,
} from "services/inspection";
import { fetchRooms } from "services/school";

import { useDispatch } from "react-redux";
import {
  Container,
  Col,
  Row,
  Button,
  Modal
} from "react-bootstrap";
import RepeatableRoomCreateForm from "components/Room/RepeatableRoomCreateForm";
import { useFormik  } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { createInspectionSuccess } from "features/inspection/inspectionSlice";

export default function RepeatableRoomCreateModal({
  open,
  onCloseModal,
  school,
  selectFirstGroup,
  setAllowSetFirstGroup
}) {
    const dispatch = useDispatch();
    const startRoomValues = {
        "name": "",
        "cleaner": "",
        "section": ""
    }
    const [isLoading, setIsLoading] = useState(false);
    const [initialRoomValues, setInitialRoomValues] = useState(startRoomValues);
 
    const roomValidationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required.'),
        cleaner: Yup.string().required("This field is required."),
        section: Yup.string().required("This field is required.")
    });

    const handleRoomSubmit = async (values) => {
        setIsLoading(true);

        const response = await createRepeatableInspection(values);
        if (response) {
            dispatch(createInspectionSuccess(response));
            dispatch(fetchRooms({ schoolId: school.id }));
            selectFirstGroup();
            setAllowSetFirstGroup();
            handleClose()
        }
       
        setIsLoading(false);
    }

    const roomFormik = useFormik({
        initialValues: initialRoomValues,
        validationSchema: roomValidationSchema,
        enableReinitialize: true,
        onSubmit: handleRoomSubmit
    });

    const handleClickSubmit = () => {
        roomFormik.handleSubmit(); 
    }
    const handleClose = () => {
        setInitialRoomValues(startRoomValues);
        onCloseModal();
        roomFormik.handleReset()
    }
    const handleCleanerSelect = ({value}) => {
        roomFormik.setFieldValue("cleaner", value);
    }
    
    return (
        <Modal
            onHide={onCloseModal}
            show={open}
            size="lg"
        >
        <Modal.Header className="mb-2 justify-content-center" >
            <Modal.Title className="text-capitalize">
                New Repeatable Room Details
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">

                <Container fluid>
                    <Row> 
                        <Col md={12}>
                            <form onSubmit={roomFormik.handleSubmit}>
                                <RepeatableRoomCreateForm
                                    values={roomFormik.values}
                                    handleChange={roomFormik.handleChange}
                                    errors={roomFormik.errors}
                                    school={school}
                                    handleCleanerSelect={handleCleanerSelect}
                                />
                            </form>
                        </Col>
                    </Row>
                </Container>
                <Modal.Footer className="p-0 pt-3 justify-content-between">
                    <Button variant="light" className="me-0 ms-auto btn-unset-height" onClick={() => handleClose()} disabled={isLoading}>Close</Button> 
                    <Button onClick={handleClickSubmit} disabled={isLoading}>Submit</Button> 
                </Modal.Footer> 
            </Modal.Body>
        </Modal>
    );
}
